.content-highlight {
  background-color: #f6f8fa;
  padding: 16px;
  font-size: 85%;
  line-height: 1.45;
  border-radius: 3px;
  overflow: auto;
  width: 100%;
  text-align: left;
  line-height: 1.5;
  font-size: 16px;
  color: #5a5659;
  font-family: "Helvetica", "Roboto", "Arial";
  margin: 0px;
}

.content-heading {
  width: 100%;
  font-size: 30px;
  color: #5a5659;
  /* color: #178C93; */
  font-family: "Segoe UI", "Roboto", "Helvetica", "Arial";
}

.content-heading2 {
  width: 100%;
  text-align: left;
  font-size: 22px;
  color: #5a5659;
  font-family: "Segoe UI", "Roboto", "Helvetica", "Arial";
}

.content-body {
  width: 100%;
  text-align: left;
  line-height: 1.5;
  font-size: 16px;
  color: #787276;
  font-family: "Helvetica", "Roboto", "Arial";
}

.content-div {
  min-width: 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: top;
  line-height: 1.5;
  width: 100%;
}

body {
  margin: 0px;
}

.content-figure {
  display: table;
  width: 1px;
  float: right;
}

.content-image {
  float: right;
  width: 100px;
}

.content-image-scaled {
  float: right;
  width: 300px;
  height: auto;
}

.content-caption {
  caption-side: bottom;
  color: #787276;
  font-size: 14px;
}

.overall-div {
  padding-left: 20px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.content-main {
  height: 87vh;
  max-width: 100%;
  width: 100%;
  flex: 1;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.hr-content {
  display: block;
  content: " ";
  border-bottom: 1px solid lightgrey;
}

.sidebar-grid {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar {
  padding-right: 20px;
  max-width: 100%;
  overflow: auto;
}

.sidebar-item {
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-item-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
}

.sidebar-icon {
  margin-right: 6px;
  color: #3f51b5;
}

.sidebar-item-text {
  width: 100%;
  font-size: 14px;
  color: #808588;
  font-family: "Roboto", "Helvetica", "Arial";
}

.sidebar-arrow {
  color: #3f51b5;
  font-size: 1.2;
  font-weight: bold;
}

.logo {
  max-width: 70%;
}

li-content {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  color: #333333;
}

li-span-content {
  font-size: 16px;
  color: #787276;
  font-weight: normal;
}

ul-content {
  list-style-type: circle;
}

b {
  color: #3f51b5;
}
