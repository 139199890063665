
.App {
  text-align: center;
  overflow-x: hidden;
}

.App-header {
  max-height: 10vh;
  padding: 0px 25px 100px 25px;
}

.Control-panel {
  padding-bottom: 14px;
}

#control {
  margin: unset;
}

.Content {
  padding: 0px 25px;
}

img:hover{
  cursor: pointer;
}

.Logo{
  width:394px;
  height:100px;
}

.Chip-spacing{
  margin: 5px;
}

.Output{
  background-color: #FFF6F3;
  max-width: calc(100% - 30px);
}

.syntaxHighlight {
  min-height: calc(50vh - 130px);
  max-height: calc(50vh - 130px);
  max-width: calc(100% - 30px);
  padding: 18.5px 14px;
  overflow: auto;
}

.MuiChip-label {
  white-space: normal !important;
  word-break: break-all !important;
  text-align: left !important;
  line-height: 1.7;
  margin: 1px !important; 
}

.MuiChip-label p {
  margin-block-start: 3px;
  margin-block-end: 3px;
} 

.MuiChip-outlined {
  height: fit-content !important;
  min-height: 25.094px !important;
}

.tbn-inputbox textarea {
  min-height: calc(50vh - 130px);
  max-height: calc(50vh - 130px);
}

.constraints-inputbox textarea {
  min-height: calc(30vh - 130px);
  max-height: calc(30vh - 130px);
}

.example-dropdown {
  max-height: 10vh;
  margin: 10px;
}

.help-button {
  float: left;
}

.clear-button {
  float: right;
  padding-top: 6px;
  padding-right: 12px;
}

.App .MuiCardContent-root:last-child {
  padding-bottom: 16px;
}

.monomer-name {
  margin-left: -8px;
  margin-top: 2px;
  margin-bottom: 2px;
  height: 19px !important;
}

.syntaxHighlight p {
  margin-top: 3px;
  margin-bottom: 3px;
}